@import '~antd/dist/antd.css';
body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial, PingFang SC-Light, Microsoft YaHei;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}
button:active {
  opacity: 0.6;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.ant-form-item-control-input input {
  flex: 1 1 auto;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 32px #fff inset;
  /**通过边框阴影遮挡住背景*/
  -webkit-text-fill-color: #333;
  /*自动填充内容的文本颜色*/
}
.custom-input {
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
  border: 1px solid #D0D3D6;
  font-size: 16px;
  color: #233041;
  background-color: white !important;
}
.login-button {
  display: block;
  margin: 12px auto;
  background: #3370FF;
  border-radius: 6px;
  height: 48px;
  width: 360px;
  color: white;
  font-size: 16px;
  line-height: 48px;
  box-sizing: content-box;
  cursor: pointer;
}
.login-button-disable {
  opacity: 0.5;
  cursor: not-allowed;
}
.register-button {
  display: block;
  margin: 0px auto;
  border: 1px solid #D0D3D6;
  border-radius: 6px;
  height: 48px;
  width: 360px;
  color: #3370FF;
  font-size: 16px;
  line-height: 48px;
  font-weight: 500;
  box-sizing: content-box;
  cursor: pointer;
}
.other-way {
  height: 1px;
  overflow: show;
  width: 100%;
  background-color: #DEE0E3;
  width: 360px;
  margin: 4px auto;
  margin-bottom: 4px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}
.other-way span {
  background: white;
  padding: 0 1em;
}
.login-active {
  color: #2468f2 !important;
}
.getcode {
  color: #3370FF;
  cursor: pointer;
}
.getcode-disable {
  color: #3370FF;
  opacity: 0.5;
  cursor: not-allowed;
}
.getcode-footer {
  margin-left: 40px;
  margin-top: -18px;
  display: block;
  color: #233041;
  opacity: 0.5;
  font-size: 14px;
}
.register .ant-form-item {
  margin-bottom: 16px;
}
.text-color {
  color: #233041;
  opacity: 0.5;
}
.text-active-color {
  color: #3370FF;
  opacity: 1;
}
.pointer {
  cursor: pointer;
}
.back-icon {
  position: absolute;
  left: 40px;
  top: 54px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.ant-btn-primary,
.ant-btn-primary:focus {
  border-color: #3370FF;
  background-color: #3370FF;
}
html {
  --antd-wave-shadow-color: #3370FF;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #3370FF;
}
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 1px #3370FF;
  background-color: #F5F8FF !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #3370FF;
}
.ant-input-affix-wrapper-focused > .ant-input:focus,
.ant-input-affix-wrapper-focused > .ant-input:active {
  background-color: transparent !important;
  outline: none !important;
}
.ant-input-affix-wrapper .input-prefix-img {
  opacity: 0.2;
}
.ant-input-affix-wrapper-focused .input-prefix-img {
  opacity: 1;
}
.forget-password:hover {
  color: #3370FF !important;
}
.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}
.ant-form-item-label {
  line-height: 40px;
}
.user-center-input {
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  border: 1px solid #D0D3D6;
  font-size: 14px;
  color: #233041;
}
.user-center-input:focus {
  box-shadow: 0 0 0 1px #3370FF;
  background-color: #F5F8FF !important;
}
.user-center-input:active {
  background-color: #F5F8FF !important;
}
.user-center-input:hover {
  border-color: #3370FF;
}
.ant-btn-primary:hover {
  background-color: #3370FFCC;
}
.ant-btn-default:hover {
  color: #3370FF;
  border-color: #3370FF;
}
.output {
  position: relative;
  width: 80px;
  overflow: hidden;
}
.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}
.upload-container {
  float: left;
  position: relative;
  width: 280px;
  height: 280px;
  background: #F8F8F8;
}
.upload-container label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.reupload {
  display: block;
  clear: both;
  color: #3370FFCC;
  margin-top: 8px;
}
.my-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
}
