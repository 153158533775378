.page {
  background-color: #ffffff;
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow: hidden;
}
.page .left-container {
  flex: 1 1 auto;
  padding: 80px 0 60px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  float: left;
  width: 72vw;
}
.page .left-container .slogan {
  margin: 0;
}
.page .custom-input {
  height: 50px;
  line-height: 50px;
  border-radius: 8px;
  border: 1px solid #D0D3D6;
  font-size: 16px;
  color: #233041;
  background-color: white !important;
}
.page .right-container {
  background-color: #3370ff;
  float: left;
  width: 28vw;
  height: 100vh;
}
.page .image_1 {
  width: 235px;
  height: 19px;
}
.page .text_1 {
  width: 480px;
  height: 56px;
  overflow-wrap: break-word;
  color: #233041;
  font-size: 40px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 56px;
}
.page .text_2 {
  width: 622px;
  height: 108px;
  overflow-wrap: break-word;
  color: #878787;
  font-size: 21px;
  font-family: PingFangSC-Regular;
  text-align: left;
  line-height: 36px;
}
.page .box_1 {
  width: 569px;
  height: 307px;
  background: url(./img/SketchPng4624a2f0347d0254f2008f6b923ac987a08be92b6dceb99c60639f5a25d0a8df.png) 100% no-repeat;
  background-size: 100% 100%;
}
.page .text-group_7 {
  width: 400px;
  height: 54px;
}
.page .text-group_7 .text_3 {
  width: 378px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.45);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 21px;
}
.page .text-group_7 .text_3 a {
  text-decoration: none;
  color: rgba(35, 48, 65, 0.45);
}
.page .text-group_7 .text_3 a:hover {
  color: #1890ff;
}
.page .text-group_7 .text_4 {
  width: 400px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.45);
  font-size: 18px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 21px;
  margin-top: 12px;
}
.page .box_2 {
  top: 0;
}
.page .box_2 .box_3 {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
  position: absolute;
  right: calc(28vw - 200px);
  width: 440px;
  border: 1px solid #dee0e3;
  margin: 50vh 0 0 -222px;
  padding-bottom: 24px;
  transform: translateY(-50%);
}
.page .box_2 .box_3 .text-wrapper_6 {
  width: 360px;
  height: 30px;
  margin: 56px 0 12px 40px;
}
.page .box_2 .box_3 .text-wrapper_6 .text_5 {
  width: 180px;
  height: 30px;
  overflow-wrap: break-word;
  color: #2468f2;
  font-size: 24px;
  font-family: PingFangSC-Regular;
  text-align: center;
  white-space: nowrap;
  line-height: 30px;
}
.page .box_2 .box_3 .text-wrapper_6 .text_6 {
  width: 180px;
  height: 30px;
  overflow-wrap: break-word;
  color: #233041;
  font-size: 24px;
  font-family: PingFangSC-Regular;
  text-align: center;
  white-space: nowrap;
  line-height: 30px;
  cursor: pointer;
}
.page .box_2 .box_3 .box_4 {
  background-color: #ffffff;
  border-radius: 8px;
  width: 360px;
  height: 52px;
  border: 1px solid #d0d3d6;
  margin: 48px 0 0 40px;
}
.page .box_2 .box_3 .box_4 .image-text_9 {
  width: 130px;
  height: 24px;
  margin: 14px 0 0 16px;
}
.page .box_2 .box_3 .box_4 .image-text_9 .block_1 {
  width: 24px;
  height: 24px;
  background: url(./img/SketchPng0c30b7e64b8fc65bab9a31034211dbad6de1e1d9701195e22cc4879349116d6d.png) 100% no-repeat;
  background-size: 100% 100%;
}
.page .box_2 .box_3 .box_4 .image-text_9 .text-group_2 {
  width: 96px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.5);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 1px;
}
.page .box_2 .box_3 .box_5 {
  background-color: #ffffff;
  border-radius: 8px;
  width: 360px;
  height: 52px;
  border: 1px solid #d0d3d6;
  margin: 12px 0 0 40px;
}
.page .box_2 .box_3 .box_5 .image-text_10 {
  width: 230px;
  height: 24px;
  margin: 14px 0 0 16px;
}
.page .box_2 .box_3 .box_5 .image-text_10 .group_1 {
  width: 24px;
  height: 24px;
  background: url(./img/SketchPng50b9dfe1abf6d1f4bd1ed3a17906e3f9d6a17f913ee8a86d1c8b8902b76dcf5f.png) 100% no-repeat;
  background-size: 100% 100%;
}
.page .box_2 .box_3 .box_5 .image-text_10 .text-group_3 {
  width: 196px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.5);
  font-size: 16px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 1px;
}
.page .box_2 .box_3 .group_3 {
  width: 360px;
  height: 20px;
  margin: 0 0 12px 40px;
}
.page .box_2 .box_3 .group_3 .image-text_11 {
  width: 122px;
  height: 20px;
}
.page .box_2 .box_3 .group_3 .image-text_11 .block_2 {
  width: 16px;
  height: 16px;
  background: url(./img/SketchPng163b8907b9a18c3858c289972fd2bb6e7e7b6e92cbd499a0fae81360237b69b3.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-top: 2px;
}
.page .box_2 .box_3 .group_3 .image-text_11 .text-group_4 {
  width: 98px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.5);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.page .box_2 .box_3 .group_3 .text_7 {
  width: 64px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.5);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: right;
  white-space: nowrap;
  line-height: 20px;
  cursor: pointer;
}
.page .box_2 .box_3 .text-wrapper_2 {
  background-color: rgba(51, 112, 255, 0.5);
  border-radius: 6px;
  height: 48px;
  width: 360px;
  margin: 24px 0 0 40px;
}
.page .box_2 .box_3 .text-wrapper_2 .text_8 {
  width: 32px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin: 13px 0 0 164px;
}
.page .box_2 .box_3 .text-wrapper_3 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 48px;
  border: 1px solid #d0d3d6;
  width: 360px;
  margin: 12px 0 0 40px;
}
.page .box_2 .box_3 .text-wrapper_3 .text_9 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #3370ff;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin: 13px 0 0 148px;
}
.page .box_2 .box_3 .box_7 {
  background-color: #e6e6e6;
  width: 360px;
  height: 1px;
  margin: 41px 0 0 40px;
}
.page .box_2 .box_3 .group_4 {
  width: 360px;
  height: 48px;
  margin: 22px 0 24px 40px;
}
.page .box_2 .box_3 .group_4 .group_2 {
  background-color: #ffffff;
  border-radius: 6px;
  width: 240px;
  height: 48px;
  border: 1px solid #e6e6e6;
}
.page .box_2 .box_3 .group_4 .group_2 .image-text_12 {
  width: 96px;
  height: 22px;
  margin: 13px 0 0 73px;
}
.page .box_2 .box_3 .group_4 .group_2 .image-text_12 .block_5 {
  height: 13px;
  background: url(./img/SketchPng858c108d496ea1d1a1ed56e151ff7f3be849155ee762e53358c6f1eb5a0dd203.png) 0px 0px no-repeat;
  background-size: 15px 13px;
  margin-top: 3px;
  width: 14px;
}
.page .box_2 .box_3 .group_4 .group_2 .image-text_12 .block_5 .box_10 {
  width: 12px;
  height: 11px;
  background: url(./img/SketchPng80ff048f69c101166736a2646820cc13ff870d39232a13fb73e41a44b74bb195.png) -1px 0px no-repeat;
  background-size: 13px 12px;
  margin: 5px 0 0 8px;
}
.page .box_2 .box_3 .group_4 .group_2 .image-text_12 .text-group_5 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: #233041;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.page .box_2 .box_3 .group_4 .image-wrapper_1 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 48px;
  border: 1px solid #e6e6e6;
  width: 112px;
}
.page .box_2 .box_3 .group_4 .image-wrapper_1 .image_2 {
  width: 81px;
  height: 12px;
  margin: 18px 0 0 16px;
}
.page .box_2 .box_3 .text-wrapper_4 {
  background-color: #ffffff;
  height: 20px;
  width: 76px;
  position: absolute;
  left: 182px;
  top: 446px;
}
.page .box_2 .box_3 .text-wrapper_4 .text_10 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(35, 48, 65, 0.5);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin-left: 10px;
}
